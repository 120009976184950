import getCart, { 
  addToCart, 
  buyNow,
  subtractFromCart,
  deleteFromCart
} from '../utils/cart-cookie';

const initialState = {
  userCart: getCart()
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case "ADD_TO_CART":     
      return {       
        ...state,
        userCart: addToCart(action.value)            
      };

    case "BUY_NOW":
      return {       
        ...state,
        userCart: buyNow(action.value)          
      };

    case "SUBTRACT_FROM_CART":
      return {       
        ...state,
        userCart: subtractFromCart(action.value)             
      };

    case "DELETE_FROM_CART":
      return {       
        ...state,
        userCart: deleteFromCart(action.value)             
      };

    default:
      return state
  }
}
