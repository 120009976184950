import thunk from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { routerReducer } from 'react-router-redux';

import stock from './reducers/stock';
import user from './reducers/user';

const rootReducer = combineReducers({
   stock,
   user,
   routing: routerReducer
});

export default createStore(
   rootReducer,
   applyMiddleware(thunk)
);
