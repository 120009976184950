import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import stockList from '@project/shared/stocks';

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { listStock } from '../../actions/stock';

function App() {
  const dispatch = useDispatch();
  const stocks = useSelector(state => state.stock.list); 

  useEffect(() => {   
    dispatch(listStock());  
  }, []);

  return (
    <div className="pr-home">
      <Header />
      <section className="pr-home__container">
        <div className="pr-home__container-inn">
          <div className="pr-home__banner">
            <img className="bg-image" src="/images/stocks-bg.jpeg" />
            <div className="pr-home__banner-fg">  
              <p className="greeting">
                Gift stocks to your friends and colleagues on every special occasion. 
              </p>           
            </div>           
          </div>

          <div className="pr-home__content">

            <div className="pr-home__pitchwrap">
              <div className="pr-home__stocktemp">
                <img src="/images/template.webp" />
                <div className="brief"></div>                
              </div>

              <div className="pr-home__pitch mobile">              
                <span className="curve">Gift stocks to your friends and colleagues on every special occasion and make them proud owners. </span>
                {/*<Link className="letter-design links" to="/letter-design">
                <i className="fa fa-play" aria-hidden="true"></i>&nbsp;See How the Letter Design Is Made
                </Link>*/} 
                <Link className="how-it-works links" to="/how-it-works">
                <i className="fa fa-play" aria-hidden="true"></i>&nbsp;See How Gifting Works
                </Link>         
              </div>
              <div className="pr-home__pitch">              
                <span className="curve">Gift & make your loved ones proud stock owners.&nbsp;</span> 
                {/*<Link className="letter-design links" to="/letter-design">
                <i className="fa fa-play" aria-hidden="true"></i>&nbsp;See How the Letter Design Is Made
                </Link>*/} 
                <Link className="how-it-works links" to="/how-it-works">
                <i className="fa fa-play" aria-hidden="true"></i>&nbsp;See How Gifting Works
                </Link>
              </div>
            </div>           
            
            <div className="pr-home__search">
              {/*
              <div className="pr-home__input">                
                <input type="text" placeholder="Search by stock name" />
                <p><i className="fa fa-search"></i></p>             
              </div>
              */}
            </div>

            <div className="pr-home__stocklist">              
              { stocks.data.length > 0 &&
                <div className="pr-home__table-head">
                  Stocks to Gift
                </div>
              }
              <div className="pr-home__table">
              { stocks.data.length > 0 &&
                  stocks.data.map((stock, i) => {                
                  return (
                    <div className="row" key={i}>
                      <div className="container">
                        <div className="logo">
                          <img src={stockList[stock.symbol].logoPath} />
                        </div>
                        <div className="details">
                          <div className="name">
                            {stockList[stock.symbol].name}
                          </div>
                          <div className="price-view">
                            <p>€{stock.price} <span>Per share</span></p>
                            <a href={"https://www.google.com/finance/quote/" + stock.symbol + "?hl=nl"}>Market View</a>
                          </div>
                          <div className="actions">
                            <Link to={"/stock-details/" + stock.symbol}>Gift Now</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

              {stocks.data.length < 1 &&
                <>
                <div className="row skeleton">
                  <div className="container">               
                  </div>
                </div>
                <div className="row skeleton">
                  <div className="container">               
                  </div>
                </div>
                 <div className="row skeleton">
                  <div className="container">               
                  </div>
                </div>
                </>
              }
              </div> 
            </div>
            {stocks.data.length > 0 &&
              <p className="pr-home__unfound">
              Couldn't find the stock you love? <Link to="/contact">Contact us</Link>, and we'll be happy to assist you!
              </p>
            }
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

