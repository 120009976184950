import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);     
  }, []);

  return (
    <div className="pr-comowner">
      <Header mode="standard" />
      <section className="pr-comowner__container">
        <div className="pr-comowner__container-inn">
          <div className="pr-comowner__content">
            <div className="pr-comowner__page">
              <p className="pr-comowner__page-name">
                Complete Ownership
              </p>
              <div className="pr-comowner__form">
                <div className="pr-comowner__input">
                  <p>
                    Enter the transfer code as stated in your envelope.
                  </p>
                  <input type="text" placeholder="Transfer Code" />
                </div>

                <div className="pr-comowner__input">
                  <div>
                    <p>
                      Enter the investment account number.
                    </p>
                    <p className="note">
                      This should be from the investment account number with the same bank as specified in the envelope for opening.
                    </p>
                  </div>
                  <input type="text" placeholder="Investment Account Number" />
                </div>

                <div className="pr-comowner__input">
                  <div>
                    <p>
                      Enter your email address.
                    </p>                    
                  </div>
                  <input type="text" placeholder="Email" />
                </div>

                <div className="pr-comowner__actions">
                  <button                   
                    className="complete"                    
                    >
                    Submit
                  </button>
                </div>
              </div>             
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

