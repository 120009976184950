import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);     
  }, []);

  return (
    <div className="pr-privacy">
      <Header mode="standard" />
      <section className="pr-privacy__container">
        <div className="pr-privacy__container-inn">
          <div className="pr-privacy__content">
            <div className="pr-privacy__page">
              <p className="pr-privacy__page-name">
                  Privacy Policy
              </p>                                             
              <div className="pr-privacy__details">
                <div>
                  At Prime Rights, we respect your privacy. We do not track your activity on our website, and we have not enabled cookies or any other tracking technologies. Your visit to our site is completely anonymous, and we do not collect or store any personal information.

                  If you have any questions about our privacy practices, please feel free to contact us.
                </div>                              
              </div>
            </div>              
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

