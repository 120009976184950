import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { createBrowserHistory } from "history";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Home from "./pages/home";
import StockDetails from "./pages/stock-details";
import ShoppingCart from "./pages/shopping-cart";
import OrderStatus from "./pages/order-status";
import LetterDesign from "./pages/letter-design";
import HowItWorks from "./pages/how-it-works";
import FAQs from "./pages/faqs";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacy-policy";
import Disclaimer from "./pages/disclaimer";
import CompleteOwnership from "./pages/complete-ownership";

import store from './store';
const customHistory = createBrowserHistory();
const helmetContext = {};

export default function RouteApp() {
  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <BrowserRouter history={customHistory}>  
          <Routes >
            <Route path='/' element={<Home/>} />
            <Route path='/stock-details/:symbol' element={<StockDetails/>} />
            <Route path='/cart' element={<ShoppingCart/>} />
            <Route path='/order-status' element={<OrderStatus/>} />
            <Route path='/letter-design' element={<LetterDesign/>} />
            <Route path='/how-it-works' element={<HowItWorks/>} />
            <Route path='/faqs' element={<FAQs/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
            <Route path='/disclaimer' element={<Disclaimer/>} />
            <Route path='/complete-ownership' element={<CompleteOwnership/>} />
          </Routes>
        </BrowserRouter>                    
      </Provider>
    </HelmetProvider>   
  );
}


