import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);     
  }, []);

  return (
    <div className="pr-contact">
      <Header mode="standard" />
      <section className="pr-contact__container">
        <div className="pr-contact__container-inn">
          <div className="pr-contact__content">
            <div className="pr-contact__page">
              <p className="pr-contact__page-name">
                  Contact
              </p>                                             
              <div className="pr-contact__details">
                <div>
                Prime Rights, operated by <span className="holding">Laissez Faire Capital</span>, is a small retailer providing a stock gifting platform dedicated to simplifying the transfer of stock ownership. Compliant with all relevant rules and regulations, Prime Rights strives to make it easy for individuals to gift stocks, enabling recipients to become proud shareholders.
                </div>
                <div className="word">
                  If you have any doubts or queries, please feel free to contact us.                 
                </div>
                <div className="reach">
                  <p><span>Email:</span>&nbsp;contact@prime-rights.nl</p>
                  <p><span>Phone:</span>&nbsp;+31(0)682231089</p>
                </div>
              </div>
            </div>              
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

