import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import stockList from '@project/shared/stocks';
import { getUpdatedStock, clearUpdatedStock } from '../../actions/stock';
import { addStockToCart, buyStockNow } from '../../actions/user';

function App() {
  const navigate = useNavigate();
  const { symbol } = useParams();
  const details = stockList[symbol];

  const dispatch = useDispatch();
  const updatedStock = useSelector(state => state.stock.updatedStock); 

  const userCart = useSelector(state => state.user.userCart);  
  const totalCart = Object.keys(userCart).reduce((sum, symbol) => {
    return sum + userCart[symbol].quantity;
  }, 0);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUpdatedStock(symbol));
    return () => {  
      dispatch(clearUpdatedStock());
    };  
  }, []);

  function addToCart() {  
    dispatch(addStockToCart(updatedStock.data));  
  }

  function buyNow() {  
    dispatch(buyStockNow(updatedStock.data)).then(() => {
      navigate('/cart');
    }); 
  } 

  return (
    <div className="pr-details">
      <Header mode="standard" />
      <section className="pr-details__container">
        <div className="pr-details__container-inn">
          <div className="pr-details__content">
            <div className="pr-details__stockinfo">
              <div className="logo">          
                <img src={details.logoPath} />
              </div>
              <div className="details">
                <p className="name">{details.name}</p>
                <p className="about">{details.about}</p>
                <div className="price-details">
                  <div className={"stock-price " + (!updatedStock.data && "stock-price-empty")}>                                                        
                    {updatedStock.data &&
                      <>
                        <div className="price">
                          <p className="amount">
                            €{updatedStock.data.price}                         
                          </p>
                          <p className="label">PER SHARE</p>
                        </div>                        
                      </>
                    }                    
                  </div>                        
                </div>
                <div className={"actions " + (updatedStock.data && "queried")}>
                  <div className={(!updatedStock.data ? "cart btn-skeleton" : "")}>
                  </div>
                  <div className={(!updatedStock.data ? "btn-skeleton" : "")}>
                  </div>
                  <div className={(!updatedStock.data ? "btn-skeleton" : "")}>
                  </div>

                  {updatedStock.data &&
                    <>
                      <div className="total-cart">
                        <span>[</span>                                                                      
                        <Link to="/cart" className="link">
                          YOUR TOTAL CART:&nbsp;&nbsp;{totalCart}
                        </Link>
                        <span>]</span>                                                                                        
                      </div>                                                                                
                      <button               
                        className="add-to-cart"                       
                        onClick={addToCart}
                      >
                        Add to Cart
                      </button>
                      <button              
                        className="checkout"                       
                        onClick={buyNow}
                      >
                        Buy Now
                      </button>
                    </>
                  }
                </div>
              </div>            
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

