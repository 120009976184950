
const initialState = {
  list: {
    isPending: false,  
    data: [],
    error: ''
  },
  updatedStock: {
    isPending: false,  
    data: null,
    error: ''
  }
};

export default function stock(state = initialState, action) {
  switch (action.type) {

    case "LIST_STOCK":
      return {       
      	...state,   
        list: {
          isPending: false,
          data: action.value.data,
          error: action.value.error
        }      
      }; 

    case "GET_UPDATED_STOCK":
      return {       
        ...state,   
        updatedStock: {
          isPending: false,
          data: action.value.data,
          error: action.value.error
        }     
      };

    case "CLEAR_UPDATED_STOCK":
      return {       
        ...state,   
        updatedStock: {
          isPending: false,
          data: null,
          error: ''
        }     
      };    
 
    default:
      return state
  }
}