"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const stocks = {
  "ASML:AMS": {
    "name": "ASML",
    "logoPath": "/images/stock/asml.svg",
    "about": "ASML Holding N.V. is a Dutch multinational corporation founded in 1984. It specializes in the development and manufacturing of photolithography machines which are used to produce computer chips."
  },
  "ABN:AMS": {
    "name": "ABN Amro Bank NV",
    "logoPath": "/images/stock/abn-amro.png",
    "about": "ABN AMRO Bank N.V. is the third-largest Dutch bank, with headquarters in Amsterdam. It was initially formed in 1991 by merger of the two prior Dutch banks that form its name, Algemene Bank Nederland (ABN) and Amsterdamsche en Rotterdamsche Bank"
  },
  "AF:EPA": {
    "name": "Air France-KLM",
    "logoPath": "/images/stock/afklm.png",
    "about": "Air France-KLM S.A., also known as Air France-KLM Group, is a French multinational airline holding company with its headquarters in the rue du Cirque, Paris, France. The group's three major brands are Air France, KLM and Transavia."
  },
  "AD:AMS": {
    "name": "Ahold Delhaize NV",
    "logoPath": "/images/stock/ahold-delhaize.png",
    "about": "Koninklijke Ahold Delhaize N.V. is a Dutch-Belgian multinational retail and wholesale holding company. Its name comes from the merger between Ahold (Dutch) and Delhaize Group (Belgian), the two merging companies which form the present-day Ahold Delhaize."
  },
  "INGA:AMS": {
    "name": "ING Groep",
    "logoPath": "/images/stock/ing.svg",
    "about": "The ING Group is a Dutch multinational banking and financial services corporation headquartered in Amsterdam."
  },
  "PNL:AMS": {
    "name": "PostNL NV",
    "logoPath": "/images/stock/postnl.png",
    "about": "PostNL N.V. is a Dutch mail, parcel and e-commerce company with operations in the Netherlands, Germany, Italy, Belgium, and the United Kingdom."
  },
  "PHIA:AMS": {
    "name": "Koninklijke Philips N.V",
    "logoPath": "/images/stock/philips.png",
    "about": "Koninklijke Philips N.V., commonly shortened to Philips, is a Dutch multinational conglomerate corporation that was founded in Eindhoven in 1891."
  },
  "ADYEN:AMS": {
    "name": "Adyen NV",
    "logoPath": "/images/stock/adyen.svg",
    "about": "Adyen is a Dutch payment company with the status of an acquiring bank that allows businesses to accept e-commerce, mobile, and point-of-sale payments."
  },
  "TKWY:AMS": {
    "name": "Just Eat Takeaway",
    "logoPath": "/images/stock/just-take-away.png",
    "about": "Just Eat Takeaway.com N.V. is a Dutch multinational online food ordering and delivery company, formed from the merger of London-based Just Eat and Amsterdam-based Takeaway.com in 2020."
  },
  "HEIA:AMS": {
    "name": "Heineken N.V",
    "logoPath": "/images/stock/heineken.png",
    "about": "Heineken N.V. is a Dutch multinational brewing company, founded in 1864 by Gerard Adriaan Heineken in Amsterdam."
  },
  "AGN:AMS": {
    "name": "AEGON",
    "logoPath": "/images/stock/aegon.svg",
    "about": "Aegon Ltd. is a Dutch public company for life insurance, pensions and asset management. It is headquartered in The Hague, Netherlands."
  },
  "FLOW:AMS": {
    "name": "Flow Traders Ltd",
    "logoPath": "/images/stock/flow-traders.svg",
    "about": "Flow Traders is a proprietary trading firm A market maker, it provides liquidity in the securities market by using high frequency and quantitative trading strategies."
  },
  "KPN:AMS": {
    "name": "Koninklijke KPN",
    "logoPath": "/images/stock/kpn-kon.svg",
    "about": "Koninklijke KPN N.V. is originated from a government-run postal, telegraph and telephone service and is based in Rotterdam, Netherlands."
  },
  "TOM2:AMS": {
    "name": "TomTom",
    "logoPath": "/images/stock/tomtom.png",
    "about": "TomTom N.V. is a Dutch multinational developer and creator of location technology and consumer electronics. Founded in 1991 and headquartered in Amsterdam, TomTom released its first generation of satellite navigation devices to market in 2004."
  },
  "ASRNL:AMS": {
    "name": "ASR Nederland",
    "logoPath": "/images/stock/asr-nederland.svg",
    "about": "ASR Nederland is a major Dutch insurance group based in Utrecht. The company was created in its current form in 2008 when the insurance business was split out of Fortis, after it was acquired by the Dutch government during the Financial crisis of 2007-2010."
  },
  "SHELL:AMS": {
    "name": "Shell PLC",
    "logoPath": "/images/stock/shell.png",
    "about": "Shell plc is a British multinational oil and gas company headquartered in London, England. Shell is a public limited company with a primary listing on the London Stock Exchange (LSE) and secondary listings on Euronext Amsterdam and the New York Stock Exchange."
  },
  "NN:AMS": {
    "name": "NN Group",
    "logoPath": "/images/stock/nn-group.png",
    "about": "Nationale-Nederlanden is one of the largest insurance and asset management companies in the Netherlands. NN Group is headquartered in The Hague, its office in Rotterdam is located in the skyscraper Gebouw Delftse Poort, which was the tallest skyscraper in the Netherlands until 2009."
  },
  "AKZA:AMS": {
    "name": "Akzo Nobel NV",
    "logoPath": "/images/stock/Akzo-Nobel.png",
    "about": "Akzo Nobel N.V., stylised as AkzoNobel, is a Dutch multinational company which creates paints and performance coatings for both industry and consumers worldwide."
  }
};
var _default = exports.default = stocks;