import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);     
  }, []);

  return (
    <div className="pr-hiworks">
      <Header mode="standard" />
      <section className="pr-hiworks__container">
        <div className="pr-hiworks__container-inn">
          <div className="pr-hiworks__content">
            <div className="pr-hiworks__page">
              <p className="pr-hiworks__page-name">
                How gifting works?
              </p>                                             
              <div className="pr-hiworks__details">
               <ol>
                <li>
                  <div className="circle">1</div> 
                  <p>
                    <span>Choose Your Gift:&nbsp;</span>
                    Select the stock you'd like to gift, enter the recipient's information, and complete your purchase.
                  </p>
                </li>
                <li>
                  <div className="circle gift">2</div> 
                  <p>
                    <span>Timeless Gift:&nbsp;</span>
                    Prime Rights will craft a beautiful gift letter with ownership details and send it directly to the recipient or to you.
                  </p>
                </li>
                <li>
                  <div className="circle">3</div> 
                  <p>
                    <span>Small Procedure:&nbsp;</span>
                    Then the recipient has 90 days to open an investment account with a bank and share the account details with Prime Rights.
                  </p>
                </li>
                <li>
                  <div className="circle">4</div> 
                  <p>
                    <span>Complete Transfer:&nbsp;</span>
                    Once we receive the account details, we'll completely transfer the stock ownership to the recipient.
                  </p>
                </li>
               </ol>
              </div>
            {/*
              <div className="pr-hiworks__regulations">
               <p>While Dutch regulations currently limit how much we can streamline the process, we are committed to finding ways to simplify it further.
               Have more questions? <Link to="/faqs">Check FAQs</Link>
               </p>               
              </div>
            */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

