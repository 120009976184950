import './style.scss';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function App({ mode = "" }) {
  const userCart = useSelector(state => state.user.userCart);
  const totalCart = Object.keys(userCart).reduce((sum, symbol) => {
    return sum + userCart[symbol].quantity;
  }, 0);

  return (
   <header className={"pr-header" + " " + mode}>
      <section className="pr-header__inn">
        <Link className="pr-header__title" to="/">
          <span className="a">PRIME</span>
          <span className="b">RIGHTS</span>
        </Link>
        <Link className="pr-header__cartwrap" to="/cart">
          <div className="cart">
            <i className="fa fa-shopping-bag" aria-hidden="true"></i>
            {totalCart > 0 &&
              <div className="count">              
                <p>{totalCart}</p>              
              </div>
            }
          </div>
        </Link>
      </section>
    </header>
  );
}

export default App;


