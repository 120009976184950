import './style.scss';

import { Link } from "react-router-dom";

function App() {
  return (
    <footer className="pr-footer">
      <section className="pr-footer__inn">
        <div className="pr-footer__row">
          <div className="pr-footer__wrap">
            <div className="pr-footer__brand">
              <p className="name">PRIME RIGHTS</p>
              <p className="notes">2024</p>
            </div>
            <ul className="pr-footer__links">
              {/*<li>
                <Link to="/letter-design">
                  See the Letter Design
                </Link>            
              </li>*/}                   
              <li>
                <Link to="/how-it-works">How it works</Link>            
              </li>
              <li>
                <Link to="/faqs">FAQs</Link>            
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>            
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>            
              </li>
              <li>
                <Link to="/contact">Contact</Link>            
              </li>                      
            </ul> 
          </div>
        </div>
        <div className="pr-footer__holding">
          <p>
            By &nbsp;<span>Laissez Faire Capital</span>
          </p>
        </div>          
      </section>    
    </footer>
  );
}

export default App;
