import './style.scss';
import React, { useState, useEffect } from 'react';
import isEmail from 'validator/lib/isEmail';

const NO_EMPTY_FIELD = new RegExp(`([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}`);

function App({
  type = "text",
  formatEg = "",
  triggerErr = "",
  disabled = false, 
  placeholder,
  validateRegExp = NO_EMPTY_FIELD,
  value,  
  onChangeHandler
}) {
  const format = formatEg && ` (E.g ${formatEg})`;
  const [input, updateInput] = useState({
    value: value,
    error: ""
  });

  useEffect(() => {
    updateInput(input);
  }, [input]);

  const isValid = (e) => {
    if (type === "email") {
      return isEmail(e.target.value);
    }

    return validateRegExp.test(e.target.value);    
  };

  const errorInfo = () => {    
    return `Invalid ${placeholder}${format}`;
  };

  const onBlur = (e) => {    
    updateInput({
      ...input,
      error: isValid(e) ? "" : errorInfo()
    });   
  };

  const onChange = (e) => {
    const err = isValid(e) ? "" : errorInfo();
    updateInput({
      value: e.target.value,
      error: err
    });
    
    onChangeHandler && onChangeHandler({value: e.target.value, error: err });    
  };

  return (
    <div className="pr-itext">
      <div 
        className={"pr-itext__inputwrap " + ((input.error || !!triggerErr) && "invalid")}>
        <input 
          type="text" 
          placeholder={placeholder}
          value={input.value}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange} 
        />
      </div>
      <div className="pr-itext__inputinfo">
        {(input.error || !!triggerErr) &&          
          <span>{errorInfo()}</span>
        }        
      </div>
    </div>
  );
}

export default App;
