import './style.scss';
import { HOUSE_NUMBER, NL_POSTCODE_REG } from '@project/shared/validations/rules';
import { calculateTotalCost } from '@project/shared/billing';
import IS_DEV, { backendURL } from '../../utils/env';
import React, { useState, useEffect } from 'react';
import { makeRequest } from '../../integrations/axios';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import InputText from "../../components/InputText";

import stockList from '@project/shared/stocks';

import { 
  addStockToCart, 
  subtractStockFromCart,
  deleteStockFromCart
} from '../../actions/user';

import { pay } from '../../actions/payment';
import { order } from '../../actions/order';

const FORM = {
  triggerErrors: {},
  hasErrors: true,
  value: {     
    recipientFirstName: "",
    recipientLastName: "",
    recipientStreetName: "",
    recipientHouseNumberAddition: "",
    recipientPostcode: "",
    recipientCity: "",
    recipientCountry: "",
    buyerNameAsWithBank: "",
    buyerEmail: ""
  },
  error: {     
    recipientFirstName: "invalid",
    recipientLastName: "invalid",
    recipientStreetName: "invalid",
    recipientHouseNumberAddition: "invalid",
    recipientPostcode: "invalid",
    recipientCity: "invalid",
    recipientCountry: "",
    buyerNameAsWithBank: "invalid",
    buyerEmail: "invalid"    
  }
};

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, updateInput] = useState({ ...FORM });
  const userCart = useSelector(state => state.user.userCart);

  const {
    stockCost, 
    VATOnStockCost, 
    serviceCost, 
    VATOnServiceCost, 
    totalCost
  } = calculateTotalCost(userCart);

  function addStock(symbol) {
    dispatch(addStockToCart(userCart[symbol])); 
  }

  function subtractStock(symbol) {
    if (userCart[symbol].quantity < 2) {
      return;
    }

    dispatch(subtractStockFromCart(symbol));
  }

  function deleteStock(symbol) {
    dispatch(deleteStockFromCart(symbol)); 
  }

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedForm = useDebounce(form, 700);

  function updateInputHandler(change, field) {
    const hasErrors = Object.keys(form.error).some((k) => {
      if (k === field) {       
        return change.error ? true : false;
      }

      return form.error[k] == "invalid" || form.error[k] !== "";
    });

    updateInput({
      ...form,
      hasErrors: hasErrors,
      triggerErrors: {
        ...form.triggerErrors,
        [field]: !!change.error
      },      
      value: {
        ...form.value,
        [field]: change.value
      },
      error: {
        ...form.error,
        [field]: change.error       
      }
    });
  };  

  const isValid = () => {
    const triggerList = Object.keys(form.error).reduce((obj, k) => {    
      if (form.error[k] == "invalid" || form.error[k] !== "") {            
        obj[k] = true;
        return obj;
      }

      return obj;      
    }, {});   

    updateInput({
      ...form,             
      triggerErrors: triggerList
    });   

    if (Object.keys(userCart).length < 1 || 
      form.hasErrors || 
      Object.keys(triggerList).length > 0
    ) {      
      return false;
    }

    return true;
  };

  const orderNow = async() => {
    const submitForm = {
      recipient: {
        firstName: form.value.recipientFirstName,
        lastName: form.value.recipientLastName,
        streetName: form.value.recipientStreetName,
        houseNumberAdd: form.value.recipientHouseNumberAddition,
        postcode: form.value.recipientPostcode,
        city: form.value.recipientCity,
        country: form.value.recipientCountry
      },
      buyer: {
        name: form.value.buyerNameAsWithBank,
        email: form.value.buyerEmail
      },
      shoppingCart: userCart       
    };

    isValid() && navigate(`/order-status`); 
    
    // return new Promise((resolve, reject) => {
    //   makeRequest({
    //     url: `/api/payment/orders`,
    //     method: 'POST',
    //     data: submitForm
    //   }).then((resp) => {      
    //     if (!resp.data.id) {
    //       return reject("Order Id is emtpy"); 
    //     }

    //     // resp.data.orderToken 
    //     return resolve(resp.data.orderDetails.id);
    //   }).catch((err) => {     
    //     reject(err);
    //   });
    // });   
  };

  const onApprove = (data, actions) =>  {
    return new Promise((resolve, reject) => {
      makeRequest({
        url: `/api/payment/orders/${data.orderID}/capture`,
        method: 'POST'
      }).then((resp) => {                 
        return navigate(`/order-status?status=${resp.data.status}`);        
      }).catch((err) => {     
        console.log(err);
      });
    });
  }

  function onCancel(data) {
    console.log("CANCELLED");
    console.log(data);
  }

  function onError(err) {
    console.log("ON_ERROR");
    console.log(err);
  }

  return (
    <div className="pr-cart">
      <Header mode="standard" />
      <section className="pr-cart__container">
        <div className="pr-cart__container-inn">
          <div className="pr-cart__content">
            <p className="pr-cart__cart-title">Shopping Cart</p>
            <div className="pr-cart__itemswrap">
              <div className={"pr-cart__itemtable " + (Object.keys(userCart).length < 1 && "empty-cart")}>
                {
                  Object.keys(userCart).map((symbol, i) => {                
                      return (
                      <div className="row" key={i}>
                        <div className="container">
                          <div className="logo">
                            <img src={stockList[symbol].logoPath} />
                          </div>
                          <div className="details">
                            <div className="name">
                              {stockList[symbol].name}
                            </div>
                            <div className="actions">
                              <div className="stuks">                           
                                  <p onClick={()=> {subtractStock(symbol)}}>                                  
                                    <i 
                                      className={"fa fa-minus " + (userCart[symbol].quantity < 2 && 'disabled')} 
                                      aria-hidden="true"
                                    >
                                    </i>
                                  </p>                                 
                                  <p >{userCart[symbol].quantity}</p>
                                  <p onClick={()=> {addStock(symbol)}}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                  </p>
                                </div>

                              <div className="delete"> 
                                <i onClick={()=> {deleteStock(symbol)}} className="fa fa-trash" aria-hidden="true"></i>
                              </div>
                            </div>
                            <div className="price">
                              €{Number(userCart[symbol].price * userCart[symbol].quantity).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className={"pr-cart__price-details " + (Object.keys(userCart).length < 1 && "empty-cart")}>
              <div>
                <table>
                  <tbody>
                    { Object.keys(userCart).length > 0 &&
                      <>
                      <tr>
                        <td className="label">Stock Worth</td>
                        <td className="amount">€{stockCost}</td>                      
                      </tr>
                      <tr>
                        <td className="label">Service Cost</td>
                        <td className="amount">€{serviceCost}</td>                      
                      </tr>
                      <tr>
                        <td className="label">
                        VAT<span className="info">&nbsp;[on service cost]</span>
                        </td>
                        <td className="amount">€{VATOnServiceCost}</td>                      
                      </tr>
                      <tr>
                        <td className="grand-label">Order Total</td>
                        <td className="grand-amount">€{totalCost}</td>                      
                      </tr>
                      </>
                    }
                    { Object.keys(userCart).length < 1 &&
                      <tr colSpan="2" className="empty-cart">
                        <td>
                          <div>
                            Your cart is empty                          
                          </div>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>          
            <div className="pr-cart__shop">
              <p className="pr-cart__form-label">
                Recipient details              
              </p>
              {form.triggerErrors["recipientFirstName"]}
              <div className="pr-cart__form">             
                <div className="row">
                  <div className="row-1">
                    <InputText
                      placeholder="First Name"                     
                      triggerErr={form.triggerErrors['recipientFirstName']}
                      value={form.value.recipientFirstName}
                      onChangeHandler={(change) => updateInputHandler(change, 'recipientFirstName')} 
                    />
                  </div>
                  <div className="row-2">
                    <InputText
                      placeholder="Last Name"             
                      triggerErr={form.triggerErrors['recipientLastName']}
                      value={form.value.recipientLastName}
                      onChangeHandler={(change) => updateInputHandler(change, 'recipientLastName')} 
                    />
                  </div>
                </div> 
                <div className="row">
                  <div className="row-1">
                    <InputText
                      placeholder="Street Name"                    
                      triggerErr={form.triggerErrors['recipientStreetName']}
                      value={form.value.recipientStreetName}
                      onChangeHandler={(change) => updateInputHandler(change, 'recipientStreetName')} 
                    />
                  </div>
                  <div className="row-2">
                    <InputText
                      placeholder="House Number & Addition"
                      validateRegExp={HOUSE_NUMBER}
                      triggerErr={form.triggerErrors['recipientHouseNumberAddition']}
                      value={form.value.recipientHouseNumberAddition}
                      onChangeHandler={(change) => updateInputHandler(change, 'recipientHouseNumberAddition')} 
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="row-1">                
                    <InputText
                      placeholder="Postcode"
                      validateRegExp={NL_POSTCODE_REG}
                      triggerErr={form.triggerErrors['recipientPostcode']}
                      formatEg="1234 AB"
                      value={form.value.recipientPostcode}
                      onChangeHandler={(change) => updateInputHandler(change, 'recipientPostcode')} 
                    />
                  </div>
                  <div className="row-2">
                    <InputText
                      placeholder="City"                     
                      triggerErr={form.triggerErrors['recipientCity']}
                      value={form.value.recipientCity}
                      onChangeHandler={(change) => updateInputHandler(change, 'recipientCity')} 
                    />
                  </div>                  
                </div>
                <div className="row">
                  <div className="row-1">
                    <InputText
                      placeholder="Country"                     
                      value="Netherlands"
                      disabled={true}               
                    />
                  </div>                  
                </div>                
              </div>
            </div>

            <div className="pr-cart__buyer">
              <p className="pr-cart__form-label">Your Contact</p>
              <div className="pr-cart__form">            
                <div className="row">
                  <div className="row-1">
                    <InputText
                      placeholder="Name (as with your iDEAL bank)"                    
                      triggerErr={form.triggerErrors['buyerNameAsWithBank']}
                      value={form.value.buyerNameAsWithBank}
                      onChangeHandler={(change) => updateInputHandler(change, 'buyerNameAsWithBank')} 
                    />
                  </div>
                  <div className="row-2">
                    <InputText
                      type="email"
                      placeholder="Email"
                      triggerErr={form.triggerErrors['buyerEmail']}                      
                      value={form.value.buyerEmail}
                      onChangeHandler={(change) => updateInputHandler(change, 'buyerEmail')} 
                    />
                  </div>
                </div> 
              </div>
            </div>

            <div className="pr-cart__actions">
              <Link className="return" to="/">Return to Stock</Link>
              <button 
                onClick={orderNow} 
                className="order-now"
                disabled={Object.keys(userCart).length < 1}
                >
                Order Now
              </button>
              {/*
                <div className="btn-wrap">
                  <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons 
                      style={styles}
                      fundingSource={"ideal"}                          
                      createOrder={createOrder}
                      onApprove={onApprove}
                      onCancel={onCancel}
                      onClick={onClick}                    
                      onError={onError}
                      // forceReRender={[debouncedForm]}                   
                    />
                  </PayPalScriptProvider>
                </div>
              */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

