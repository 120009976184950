import Cookies from 'js-cookie';

const USER_CART = 'USER_CART';

const setCart = (value) => {
  Cookies.set(USER_CART, JSON.stringify(value), { expires: 30 });
}

const getCart = () => {
  const cartJSON = Cookies.get(USER_CART);
  if (cartJSON) {
    return JSON.parse(cartJSON); 
  } 
 
  setCart({});
  return {};
};

export const addToCart = (stock) => {
  const { symbol } = stock;
  const cart = getCart();

  if (cart[symbol]) {
    cart[symbol] = {
      ...cart[symbol],
      quantity: cart[symbol].quantity + 1
    };
    setCart(cart);
    return cart;
  }

  cart[symbol] = {  
    ...stock,
    quantity: 1
  };

  setCart(cart);
  return cart;
};

export const buyNow = (stock) => {
  const { symbol } = stock;
  const cart = getCart();
  if (cart[symbol]) {
    return cart;
  }

  cart[symbol] = {  
    ...stock,
    quantity: 1
  };

  setCart(cart);
  return cart;
};

export const subtractFromCart = (symbol) => {
  const cart = getCart(); 
  cart[symbol] = {
    ...cart[symbol],
    quantity: cart[symbol].quantity - 1
  };
  setCart(cart);
  return cart;
};

export const deleteFromCart = (symbol) => {
  const cart = getCart();
  delete cart[symbol];
  setCart(cart);
  return cart;
};

export default getCart;
