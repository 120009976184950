import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);     
  }, []);

  return (
    <div className="pr-faqs">
      <Header mode="standard" />
      <section className="pr-faqs__container">
        <div className="pr-faqs__container-inn">
          <div className="pr-faqs__content">
            <div className="pr-faqs__page">
              <p className="pr-faqs__page-name">
                Frequently Asked Questions
              </p>                                             
              <div className="pr-faqs__details">
                <section className="pr-faqs__qarow">
                  <div className="question">
                    What happens if the recipient doesn't provide investment account details within 90 days?                 
                  </div>
                  <div className="answer">
                    We retain €10 plus 1% of the stock's value, and then refund the remaining amount to the original purchaser of the gift.               
                  </div>
                </section>
                <section className="pr-faqs__qarow">
                  <div className="question">
                    Can the recipient open an investment account with any bank?                
                  </div>
                  <div className="answer">
                    No. The recipient needs to open the investment account with the bank specified in the letter. This is usually <span className="italic">ABN AMRO, ING, or Rabobank</span>. Later, the recipient can transfer the shares however they want. This is not a rule from us; it comes from Dutch regulations.                
                  </div>
                </section>
                <section className="pr-faqs__qarow">
                  <div className="question">
                    Can the recipient provide his existing investment account?                
                  </div>
                  <div className="answer">
                    Yes, if the investment account is with the same bank as specified in the letter.                 
                  </div>
                </section>

                <section className="pr-faqs__qarow">
                  <div className="question">
                    Once the transfer is complete, can the recipient transfer the shares to his other investment accounts?                
                  </div>
                  <div className="answer">
                    Yes, the recipient is the owner of the stock and can do whatever they want with it.                
                  </div>
                </section>

                <section className="pr-faqs__qarow">
                  <div className="question">
                    Is there a limit on buying stock as a gift?              
                  </div>
                  <div className="answer">
                    Yes, a person can gift a maximum of €2,658 worth of stock to the recipient.              
                  </div>
                </section>

                <section className="pr-faqs__qarow">
                  <div className="question">
                    Would the recipient be subject to gift tax?             
                  </div>
                  <div className="answer">
                    Yes, if the gift exceeds €2,658. Read more about it here &nbsp;
                    <a href="https://www.blueumbrella.nl/faq/expat-in-the-netherlands/living-in-the-netherlands/gift-tax" target="_blank">
                      Dutch Gift tax
                    </a>            
                  </div>
                </section>
              </div>
            </div> 
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

