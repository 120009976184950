import * as stockAPI from '../api/stock';

export const listStock = (idToken) => async (dispatch) => {  
  dispatch({
    type: "LIST_STOCK",
    value: await stockAPI.listStock()    
  });
};

export const getUpdatedStock = (symbol) => async (dispatch) => {  
  dispatch({
    type: "GET_UPDATED_STOCK",
    value: await stockAPI.getUpdatedStock(symbol)    
  });
};

export const clearUpdatedStock = () => async (dispatch) => {  
  dispatch({
    type: "CLEAR_UPDATED_STOCK"     
  });
};


