import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);     
  }, []);

  return (
    <div className="pr-orderstatus">
      <Header mode="standard" />
      <section className="pr-orderstatus__container">
        <div className="pr-orderstatus__container-inn">
          <div className="pr-orderstatus__content">
            <div className="pr-orderstatus__message">
              <p className="status">Your order has been successfully processed!</p>
              <p className="notes"> Please refer to your email for further instructions.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

