"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.currency = exports.calculateTotalCost = exports.calculateStockCost = exports.calculateServiceCost = void 0;
const VAT_NL = 0.21; // 21%
const STOCK_VARIABLE_COST = 0.01; // 1%  
const FIXED_SERVICE_COST = 15;
const PACKAGING_COST = 3;
const currency = exports.currency = {
  "EURO": "€",
  "USD": "$"
};
const calculateStockCost = cart => {
  const total = Object.keys(cart).reduce((total, symbol) => {
    return total + cart[symbol].price * cart[symbol].quantity;
  }, 0);
  return {
    stockCost: Number(total.toFixed(2)),
    VATOnStockCost: 0
  };
};
exports.calculateStockCost = calculateStockCost;
const calculateServiceCost = stockCost => {
  const serviceCost = Number((FIXED_SERVICE_COST + PACKAGING_COST + stockCost * STOCK_VARIABLE_COST).toFixed(2));
  const VATOnServiceCost = Number((VAT_NL * serviceCost).toFixed(2));
  return {
    serviceCost,
    VATOnServiceCost
  };
};
exports.calculateServiceCost = calculateServiceCost;
const calculateTotalCost = cart => {
  const {
    stockCost,
    VATOnStockCost
  } = calculateStockCost(cart);
  const {
    serviceCost,
    VATOnServiceCost
  } = calculateServiceCost(stockCost);
  const totalCost = Number((stockCost + VATOnStockCost + serviceCost + VATOnServiceCost).toFixed(2));
  return {
    stockCost,
    VATOnStockCost,
    serviceCost,
    VATOnServiceCost,
    totalCost
  };
};
exports.calculateTotalCost = calculateTotalCost;