import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);     
  }, []);

  return (
    <div className="pr-hiworks">
      <Header mode="standard" />
      <section className="pr-hiworks__container">
        <div className="pr-hiworks__container-inn">
          <div className="pr-hiworks__content">
            <div>
              <div>
                LETTER DESIGN
              </div>
              <div>
                (Working on this page...)
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

