import { makeRequest } from '../integrations/axios';

export const listStock = async () => makeRequest({
  url: `/api/stock`,
  method: 'GET'
});

export const getUpdatedStock = async (symbol) => makeRequest({
  url: `/api/stock/${symbol}`,
  method: 'GET'
});

