import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);     
  }, []);

  return (
    <div className="pr-dis">
      <Header mode="standard" />
      <section className="pr-dis__container">
        <div className="pr-dis__container-inn">
          <div className="pr-dis__content">
            <div className="pr-dis__page">
              <p className="pr-dis__page-name">
                Disclaimer
              </p>                                             
              <div className="pr-dis__details">
                <div>
                  Prime Rights is a small retailer that facilitates the gifting of stocks with a limited transaction value. Prime Rights is not a registered brokerage firm, securities dealer, or investment advisor and does not offer investment advice, recommendations, or services related to the buying, selling, or trading of securities. All names, logos, and trademarks displayed on this platform are the exclusive property of their respective owners and are used solely for informational purposes in connection with the corresponding stocks. Prime Rights is not affiliated with, endorsed by, or associated with these companies in any manner.
                </div>                              
              </div>
            </div>              
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;

