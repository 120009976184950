export const addStockToCart = (stock) => async (dispatch) => {  
  dispatch({
    type: "ADD_TO_CART",
    value: stock    
  });
};

export const buyStockNow = (stock) => async (dispatch) => {  
  dispatch({
    type: "BUY_NOW",
    value: stock    
  });

  return Promise.resolve(); 
};

export const subtractStockFromCart = (symbol) => async (dispatch) => {  
  dispatch({
    type: "SUBTRACT_FROM_CART",
    value: symbol    
  });
};

export const deleteStockFromCart = (symbol) => async (dispatch) => {  
  dispatch({
    type: "DELETE_FROM_CART",
    value: symbol   
  });
};
