import axios from 'axios';
// import authToken from './cookie';
import store from '../store';
import { backendURL } from '../utils/env';

const router = axios.create({
  baseURL: backendURL
});

router.interceptors.request.use((config) => {  
  config.headers = {
    ...config.headers
    // Authorization: `Bearer ${authToken() || ''}`     
  };
  
  return config;
}, error => Promise.reject(error));

router.interceptors.response.use((response) => response, (error) => {  
  if (error.response) {    
    if (error.response.status === 401) {
      // store.dispatch(toggleLedgerModal(false));     
      // return store.dispatch(toggleLoginModal(true));
    }
  }
  return Promise.reject(error);
});

const errorInterceptor = async (request) => {
  const reply = { data: null, error: '', status: 0 };
  try {
    const data = await request;     
    reply.data = data.data;
  } catch (e) {  
    reply.error = (e.response && e.response.data) ? e.response.data : 'Something went wrong';
    reply.status = (e.response && e.response.status) ? e.response.status : 0;
  }

  return reply;
};

export const makeRequest = (reqConfig) => { 
  return errorInterceptor(router({
    ...reqConfig
  }));
};
